/* Font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

/* General */
html,
body {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  font-family: 'Open Sans', sans-serif;
}

/* Header */

.header {
  border-bottom: 2px solid rgba(248, 248, 248, 0.75);
}

.logo {
  width: 68px;
}

/* Menu */

.navbar-nav .nav-link.active {
  font-weight: 600;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* Tabs */
.department-tabs-wrapper .nav-tabs .nav-link {
  color: #be0a15;
  border: 1px solid #e9e9e9;
}

@media (min-width: 768px) {
  .nav-tabs .nav-link {
    min-width: 280px;
  }
}

.department-tabs-wrapper .nav-tabs .nav-link.active {
  background-color: #be0a15;
  color: #ffffff;
}

.logistics-date-tabs-wrapper .nav-tabs .nav-link {
  color: #253746;
  border: 1px solid #e9e9e9;
}

.logistics-date-tabs-wrapper .nav-tabs .nav-link.active {
  background-color: #253746;
  color: #ffffff;
}

/* Orderlist */
.orderlist .text-align-end {
  text-align: end;
}

/* Turnover */

.turnover-grid {
  display: grid;
  gap: 1em;
}

@media (min-width: 1024px) {
  .turnover-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1023px) {
  .turnover-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 481px) {
  .turnover-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1900px) {

  .turnover-grid .badge,
  .turnover-grid .card-subtitle,
  .turnover-grid .card-text {
    font-size: 20px;
  }

  .turnover-grid .turnover {
    font-size: 24px;
  }

  .turnover-grid .card {
    min-height: 160px;
  }
}

@media (max-width: 1899px) {
  .turnover-grid .card {
    min-height: 140px;
  }
}

.turnover-grid .badge {
  background-color: #253746 !important;
  text-transform: capitalize;
}

.turnover-grid .card-body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.turnover-grid .card-body svg {
  margin-right: 8px;
}

.turnover {
  font-weight: 600;
}

.current-week {
  background-color: #f8f8f8;
}

.turnover-period {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.turnover-period .dropdown {
  margin-left: 12px;
}


/* Logistics - Drag and drop */

.new-drag-area {
  border: 1px dashed #253746;
  padding: 20px;
}

.logistics-grid-trips {
  gap: 1em;
  margin-left: 0;
  margin-right: 0;
}

.logistics-grid-trips .card {
  max-width: 30rem;
  padding: 0;
}

.dnd-selections .accordion-button:not(.collapsed) {
  color: #212529;
  background-color: #ffffff;
  box-shadow: none;
}

.dnd-selections .accordion-button {
  padding-left: 0;
}

.dnd-selections .accordion-button:focus {
  box-shadow: none;
}


.dnd-selections .accordion-body {
  padding: 1em 0 0 0;
}

.dnd-selections .accordion-body button {
  margin: 0 8px 8px 0;
  touch-action: none;
}

.project-type {
  display: block;
  text-transform: capitalize;
}



.alert {
  display: inline-block;
  width: fit-content;
  padding: 6px 12px;
  margin-right: 6px;
}

.pdf-popup .popover {
  max-width: fit-content;
}

.custom-popover {
  max-width: fit-content;
}

/* Buttons */
.btn {
  border: none;
}

.btn-close {
  margin-left: 15px;
  float: right;
}

/* Color */

.dark-blue {
  background-color: #253746;
}

.red {
  background-color: #e30613;
}

.dark-red {
  background-color: #be0a15;
}

.white {
  background-color: #ffffff;
}

.black {
  background-color: #333333;
}

.gray {
  background-color: #f8f8f8;
  color: #212529;
}

/* Export */

.date-input {
  width: 150px;
}

.aligned-form {
  display: flex;
  align-items: center;
}

.form-container {
  width: 150px;
}

.btn-dl {
  width: fit-content;
  align-items: flex-end;
}

.btn-show {
  width: fit-content;
  align-items: flex-start;
}

.btn-container {
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.export-alert {
  margin-left: 4px;
}

.form-inline-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.export-form {
  margin-bottom: 20px;
}

.export-container {
  width: 100%;
}

/* Toast */

.toast-b-right {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  width: auto;
}

/* Spinner */

.custom-spinner {
  color: #333333;
}

/* Errors */

.error-message {
  color: red;
  margin-left: 5px;
  font-size: smaller;
  align-items: center;
}

.error-container {
  min-height: 20px;
}

.error-message.hidden{
  visibility: hidden;
}

